import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import { Formik } from "formik"
import * as Yup from "yup"
import Container from "@components/styled/Container"
import Layout from "@components/layout"
import SEO from "@components/seo"
import TextInput from "@components/TextInput"
import SelectInput from "@components/Select"
import moment from "moment"
import { SingleDatePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import Columns from "@components/styled/Columns"
import Column from "@components/styled/Column"
import Loading from "@components/Loading"
import get from "lodash/get"
import { base } from "@helpers/airtable"
import { mapOptions, valueOrNothing, formatAirtableDate } from "@helpers/data"
import { authCheckRedirect } from "@helpers/auth"
import {
  FormHead,
  Form,
  FormSection,
  FieldGroup,
  FormActions,
  Textarea
} from "@components/Form"
import Back from "@components/Back"
const initialFormState = {
  // Basics
  event_name: "",
  event_type: "",
  organisation: "",
  presentation_name: "",
  format: "",
  // Time and place
  start_date: moment(),
  end_date: moment(),
  time: "",
  state_territory: "",
  city: "",
  // Details
  survey_level: "",
  attendees: 0,
  survey_respondees: 0,
  // Other
  comments: ""
}

const validationSchema = Yup.object().shape({
  event_name: Yup.string().required("This is required"),
  event_type: Yup.string().required("This is required"),
  organisation: Yup.string().required("This is required"),
  presentation_name: Yup.string().required("This is required"),
  format: Yup.string().required("This is required"),
  start_date: Yup.string().required("This is required"),
  end_date: Yup.string().required("This is required"),
  state_territory: Yup.string().required("This is required"),
  city: Yup.string().required("This is required")
})

const AddEventPage = ({ user, data, ...props }) => {
  const [submissionError, setSubmissionError] = useState(false)
  const [formState, setFormState] = useState(initialFormState)
  const [eventId, setEventId] = useState("")
  const [loading, setLoading] = useState(true)
  const [calendarFocus, setCalendarFocus] = useState({
    start_date: false,
    end_date: false
  })

  useEffect(() => {
    authCheckRedirect()
    const fetchEvent = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const eventId = urlParams.get("id")
      if (!eventId) {
        navigate("/view-events")
      }

      setEventId(eventId)

      const recordEvent = await base("Events").find(eventId)
      const { fields } = recordEvent

      setFormState({
        // Basics
        event_name: valueOrNothing(fields["Event name"]),
        event_type: valueOrNothing(fields["Event Type"]),
        organisation: valueOrNothing(fields["Organisation"]),
        presentation_name: valueOrNothing(fields["Presentation name"]),
        format: valueOrNothing(fields["Format"]),
        // Time and place
        start_date: formatAirtableDate(fields["Start Date"]),
        end_date: formatAirtableDate(fields["End Date"]),
        time: valueOrNothing(fields["Time"]),
        state_territory: valueOrNothing(fields["State / Territory"]),
        city: valueOrNothing(fields["City"]),
        // Details
        survey_level: valueOrNothing(fields["Survey Level"]),
        attendees: valueOrNothing(fields["Attendees"]) || 0,
        survey_respondees: valueOrNothing(fields["Survey Respondees"]) || 0,
        // Other
        comments: valueOrNothing(fields["Comments"])
      })
      setLoading(false)
    }

    fetchEvent()
  }, [])

  const stateTerritoryOptions = mapOptions(data.stateTerritoryOptions.edges)

  const eventTypeOptions = mapOptions(data.eventTypeOptions.edges)
  const eventFormatOptions = mapOptions(data.eventFormatOptions.edges)
  const cityOptions = mapOptions(data.cityOptions.edges)
  const surveyLevelOptions = mapOptions(data.surveyLevelOptions.edges)

  const onCalendarFocusChange = (e, type) => {
    setCalendarFocus({ ...calendarFocus, [type]: e.focused })
  }

  const updateEvent = async values => {
    const event = {
      id: eventId,
      fields: {
        // Basics
        "Event name": values.event_name,
        "Event Type": [values.event_type],
        Organisation: values.organisation,
        "Presentation name": values.presentation_name,
        Format: [values.format],
        // Time and place
        "State / Territory": [values.state_territory],
        City: [values.city],
        "Start Date": values.start_date.format("YYYY-MM-DD"),
        "End Date": values.end_date.format("YYYY-MM-DD"),
        Time: values.time,
        // Details
        "Survey Level": values.survey_level ? [values.survey_level] : [],
        Attendees: values.attendees,
        "Survey Respondees": values.survey_respondees,
        Comments: values.comments
      }
    }

    return base("Events").update([event])
  }

  const handleSubmit = async (values, actions) => {
    try {
      await updateEvent(values)
      actions.setSubmitting(false)
      navigate("/view-events")
    } catch (e) {
      setSubmissionError(true)
      actions.setSubmitting(false)
      console.log(e, "error")
    }
  }

  const deleteEvent = async () => {
    await base("Events").destroy([eventId])
    navigate("/view-events")
  }

  return (
    <Layout uri={props.uri}>
      <SEO title="Edit event" />
      <Container pt={[7]}>
        <section>
          <Back to="/view-events" />
          <FormHead title="Event" />
          {loading ? (
            <Loading />
          ) : (
            <Formik
              enableReinitialize
              initialValues={formState}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                submitForm,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
                resetForm,
                values,
                errors,
                touched
              }) => (
                <Form>
                  <FormSection title="Basics">
                    <Columns>
                      <Column>
                        <FieldGroup
                          required
                          name="event_name"
                          label="Event name"
                        >
                          <TextInput
                            name="event_name"
                            value={values.event_name}
                            onChange={handleChange}
                            error={
                              get(touched, "event_name") &&
                              get(errors, "event_name") &&
                              get(errors, "event_name")
                            }
                            onBlur={handleBlur}
                          />
                        </FieldGroup>
                      </Column>

                      <Column width={[1, 0.5]}>
                        <FieldGroup
                          required
                          name="event_type"
                          label="Event type"
                        >
                          <SelectInput
                            name="event_type"
                            value={values.event_type}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={
                              get(touched, "event_type") &&
                              get(errors, "event_type") &&
                              get(errors, "event_type")
                            }
                            options={eventTypeOptions}
                          />
                        </FieldGroup>
                      </Column>

                      <Column>
                        <FieldGroup
                          required
                          name="presentation_name"
                          label="Presentation name"
                        >
                          <TextInput
                            name="presentation_name"
                            value={values.presentation_name}
                            onChange={handleChange}
                            error={
                              get(touched, "presentation_name") &&
                              get(errors, "presentation_name") &&
                              get(errors, "presentation_name")
                            }
                            onBlur={handleBlur}
                          />
                        </FieldGroup>
                      </Column>

                      <Column width={[1, 0.5]}>
                        <FieldGroup required name="format" label="Event format">
                          <SelectInput
                            name="format"
                            value={values.format}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={
                              get(touched, "format") &&
                              get(errors, "format") &&
                              get(errors, "format")
                            }
                            options={eventFormatOptions}
                          />
                        </FieldGroup>
                      </Column>

                      <Column>
                        <FieldGroup
                          required
                          name="organisation"
                          label="Organisation"
                        >
                          <TextInput
                            name="organisation"
                            value={values.organisation}
                            onChange={handleChange}
                            error={
                              get(touched, "organisation") &&
                              get(errors, "organisation") &&
                              get(errors, "organisation")
                            }
                            onBlur={handleBlur}
                          />
                        </FieldGroup>
                      </Column>
                    </Columns>
                  </FormSection>

                  <FormSection title="Time and place">
                    <Columns>
                      <Column>
                        <FieldGroup name="startDate_input" label="Start date">
                          <SingleDatePicker
                            id="startDate_input"
                            date={values.start_date}
                            focused={calendarFocus.start_date}
                            onDateChange={e => setFieldValue("start_date", e)}
                            onFocusChange={e =>
                              onCalendarFocusChange(e, "start_date")
                            }
                            numberOfMonths={1}
                          />
                        </FieldGroup>
                      </Column>

                      <Column>
                        <FieldGroup name="endDate_input" label="End date">
                          <SingleDatePicker
                            id="endDate_input"
                            date={values.end_date}
                            focused={calendarFocus.end_date}
                            onDateChange={e => setFieldValue("end_date", e)}
                            onFocusChange={e =>
                              onCalendarFocusChange(e, "end_date")
                            }
                            numberOfMonths={1}
                          />
                        </FieldGroup>
                      </Column>
                      <Column>
                        <FieldGroup name="time" label="Time">
                          <TextInput
                            name="time"
                            value={values.time}
                            onChange={handleChange}
                            error={
                              get(touched, "time") &&
                              get(errors, "time") &&
                              get(errors, "time")
                            }
                            onBlur={handleBlur}
                          />
                        </FieldGroup>
                      </Column>
                    </Columns>

                    <Columns>
                      <Column width={[1, 0.5]}>
                        <FieldGroup
                          required
                          name="state_territory"
                          label="State/Territory"
                        >
                          <SelectInput
                            name="state_territory"
                            value={values.state_territory}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={
                              get(touched, "state_territory") &&
                              get(errors, "state_territory") &&
                              get(errors, "state_territory")
                            }
                            options={stateTerritoryOptions}
                          />
                        </FieldGroup>
                      </Column>
                      <Column width={[1, 0.5]}>
                        <FieldGroup required name="city" label="City">
                          <SelectInput
                            name="city"
                            value={values.city}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={
                              get(touched, "city") &&
                              get(errors, "city") &&
                              get(errors, "city")
                            }
                            options={cityOptions}
                          />
                        </FieldGroup>
                      </Column>
                    </Columns>
                  </FormSection>

                  <FormSection title="Details">
                    <Columns>
                      <Column width={[1, 0.5]}>
                        <FieldGroup name="survey_level" label="Survey level">
                          <SelectInput
                            name="survey_level"
                            value={values.survey_level}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={
                              get(touched, "survey_level") &&
                              get(errors, "survey_level") &&
                              get(errors, "survey_level")
                            }
                            options={surveyLevelOptions}
                          />
                        </FieldGroup>
                      </Column>
                      <Column>
                        <FieldGroup name="attendees" label="Attendees">
                          <TextInput
                            type="number"
                            name="attendees"
                            value={values.attendees}
                            onChange={handleChange}
                          />
                        </FieldGroup>
                      </Column>
                      <Column>
                        <FieldGroup
                          name="survey_respondees"
                          label="Survey respondees"
                        >
                          <TextInput
                            type="number"
                            name="survey_respondees"
                            value={values.survey_respondees}
                            onChange={handleChange}
                          />
                        </FieldGroup>
                      </Column>
                    </Columns>
                  </FormSection>

                  <FormSection>
                    <FieldGroup name="comments" label="Comments">
                      <Textarea
                        name="comments"
                        id="comments"
                        value={values.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FieldGroup>
                  </FormSection>

                  <FormActions
                    ctaLabel="Update"
                    hideClear
                    showDelete
                    onDeleteClick={deleteEvent}
                    onSubmitClick={submitForm}
                    onClearClick={() => resetForm(initialFormState)}
                    submitDisabled={isSubmitting}
                    error={submissionError}
                  />
                </Form>
              )}
            </Formik>
          )}
        </section>
      </Container>
    </Layout>
  )
}

export default AddEventPage

export const query = graphql`
  query {
    eventTypeOptions: allAirtable(filter: { table: { eq: "DL_Event_Type" } }) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
    stateTerritoryOptions: allAirtable(
      filter: { table: { eq: "DL_State_Territory" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
    cityOptions: allAirtable(filter: { table: { eq: "DL_City" } }) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
    surveyLevelOptions: allAirtable(
      filter: { table: { eq: "DL_Survey_Level" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
    eventFormatOptions: allAirtable(
      filter: { table: { eq: "DL_Event_Format" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
  }
`
